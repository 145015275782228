import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useState } from "react";
import { PayrollSettingsApi } from "../../components/ApiBaseUrl";

const PayrollSettings = () => {

    const [formData, setFormData] = useState({
        hra: "",
        da: "",
        pf: "",
        pt: "",
        esi: "",
        lwf: "",
      });
    
      // Mapping of field names to their full descriptions
      const fieldLabels = {
        hra: "House Rent Allowance (HRA)",
        da: "Dearness Allowance (DA)",
        pf: "Provident Fund (PF)",
        pt: "Professional Tax (PT)",
        esi: "Employee State Insurance (ESI)",
        lwf: "Labour Welfare Fund (LWF)",
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
       
        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
          setFormData({
            ...formData,
            [name]: value,
          });
        }
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData)
        // Validate required fields
        const requiredFields = ["hra", "da", "pf", "pt", "esi", "lwf"];
        for (const field of requiredFields) {
          if (!formData[field]) {
            alert(`${fieldLabels[field]} is required.`);
            return;
          }
        }
    
        try {
          // Submit the data to API
          const response = await PayrollSettingsApi("POST", "savePayrollSettings", formData);
          console.log("API Response:", response);
          alert("Payroll settings saved successfully!");
        } catch (error) {
          console.error("Error submitting payroll settings:", error.message);
          alert(`Failed to save payroll settings: ${error.message}`);
        }
      };

    return (
        <>
            <div className="flex h-screen">
                <Sidebar />
                <div className="flex-1 bg-white divide-y overflow-auto">
                    <Navbar />
                    <div className="container mx-auto p-4 justify-center">
                        <form
                            className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4"
                            onSubmit={handleSubmit}
                        >
                            <h2 className="text-xl font-bold text-gray-700">Payroll Settings</h2>
                            {Object.keys(formData).map((field) => (
                                <div key={field} className="flex flex-col">
                                    <label
                                        htmlFor={field}
                                        className="mb-1 text-sm font-medium text-gray-600"
                                    >
                                        {fieldLabels[field]}
                                    </label>
                                    <input
                                        type="text"
                                        id={field}
                                        name={field}
                                        value={formData[field]}
                                        onChange={handleChange}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                                            if ((e.target.value.match(/\./g) || []).length > 1) {
                                                e.target.value = e.target.value.slice(0, -1); // Prevent multiple dots
                                            }
                                        }}
                                        placeholder={`Enter ${fieldLabels[field]}`}
                                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                            ))}
                            <button
                                type="submit"
                                className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PayrollSettings;