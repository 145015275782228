import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useState, useEffect, useCallback } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const View_attendance = () => {
  const [employees, setEmployees] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);

  const daysPerPage = 16; // Number of days to show per page
  const attendanceThreshold = 1440; // Maximum threshold in minutes

  const generateAttendanceData = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, () => (Math.random() > 0.4 ? 1440 : 0));
  };

  const generateMockEmployeeData = useCallback((year, month) => {
    return [
      {
        emp_id: "E001",
        name: "John Smith",

        attendance: generateAttendanceData(year, month)

      },
      {
        emp_id: "E002",
        name: "Maria Antonova",
        attendance: generateAttendanceData(year, month)
      },
      {
        emp_id: "E003",
        name: "David Branson",
        attendance: generateAttendanceData(year, month)
      },
      {
        emp_id: "E004",
        name: "Linda Johnson",
        attendance: generateAttendanceData(year, month)
      },
      {
        emp_id: "E005",
        name: "Michael Brown",
        attendance: generateAttendanceData(year, month)
      },
      {
        emp_id: "E006",
        name: "Patricia Taylor",
        attendance: generateAttendanceData(year, month)
      }
    ];
  }, []);

  const fetchEmployeeDataForDate = useCallback((date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const lastDayOfMonth = new Date(year, month + 1, 0);
    setEndDate(lastDayOfMonth);

    const mockEmployeeData = generateMockEmployeeData(year, month);
    setEmployees(mockEmployeeData);
  }, [generateMockEmployeeData]);

  useEffect(() => {
    fetchEmployeeDataForDate(new Date());
  }, [fetchEmployeeDataForDate]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
      fetchEmployeeDataForDate(date);
    } else {
      setEndDate(null); // Reset end date if start date is cleared
    }
  };

  const formatDateDisplay = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Calculate total pages based on selected date range
  const totalPages = endDate ? Math.ceil(endDate.getDate() / daysPerPage) : 1;

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const getFilteredDaysInRange = () => {
    const start = startDate ? startDate.getDate() - 1 : 0;
    const end = endDate ? endDate.getDate() : 31;
    const daysInRange = [...Array(end).keys()].slice(start);

    // Paginate days based on current page and daysPerPage
    const pageStart = currentPage * daysPerPage;
    return daysInRange.slice(pageStart, pageStart + daysPerPage);
  };

  const filteredEmployees = employees.filter((employee) => {
    const matchesQuery = searchQuery
      ? employee.name.toLowerCase().includes(searchQuery) ||
      employee.emp_id.toLowerCase().includes(searchQuery)
      : true;
    return matchesQuery;
  });

  const filteredAttendance = (attendance) => {
    switch (filter) {
      case "Attended":
        return attendance >= attendanceThreshold;
      case "Absent":
        return attendance === 0;
      case "Below Threshold":
        return attendance > 0 && attendance < attendanceThreshold;
      default:
        return true;
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <Sidebar />
      <div className="flex-1 overflow-y-auto">
        <div className="p-4 md:p-6">
          <div className="grid md:grid-cols-2">
            <div>
              <h1 className="text-2xl font-semibold text-gray-700 mb-4">Time and Attendance</h1>
            </div>

            <div className="md:ml-auto">
              <button
                onClick={() => document.getElementById("datepicker").focus()}
                className="flex items-center border border-green-600 rounded-full px-4 py-2 shadow-lg cursor-pointer"
              >
                <CiCalendar className="text-green-600 mr-2" size={20} />
                <span className="text-green-500 font-semibold">
                  {startDate && endDate ? `${formatDateDisplay(startDate)} - ${formatDateDisplay(endDate)}` : "Select Date Range"}
                </span>
              </button>
              <DatePicker
                id="datepicker"
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="MMMM d, yyyy"
                placeholderText="Select start date"
                customInput={<input className="opacity-0" />}
              />
            </div>

            <hr />
            <hr />

            <div>
              <IoSearchOutline className="absolute top-[134px] right-[950px] text-slate-400" />
              <input
                type="text"
                placeholder="Search Employee"
                value={searchQuery}
                onChange={handleSearchChange}
                className="p-2 border border-1 border-slate-300 bg-gray-100 rounded flex-1 md:w-80 mt-8 shadow-lg"
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-green-400 pointer-events-none"></span>
            </div>

            <button className="py-2 h-fit px-6 border-2 text-green-500 border-green-500 rounded w-fit mt-5 md:ml-auto shadow-lg">
              Export
            </button>
          </div>

          {/* Button Filters */}
          <div className="flex md:space-x-8 my-9 items-center text-center">
            <Link
              onClick={() => setFilter("All")}
              className={`px-4 py-2 text-lg rounded ${filter === "All" ? "text-black" : "text-blue-500"} transition duration-300`}
            >
              All
            </Link>

            <div>
              <Link onClick={() => setFilter("Attended")}
                className={`px-2 py-2 rounded ${filter === "Attended" ? "text-green-600" : "text-gray-800"} transition duration-300 `}>
                <span className="inline-block w-4 h-4 bg-green-300 rounded-full mr-2"></span>
                <span className="text-lg">Attended</span>
              </Link>
            </div>

            <div>
              <Link
                onClick={() => setFilter("Absent")}
                className={`px-2 py-2 text-lg rounded ${filter === "Absent" ? "text-red-700" : "text-gray-800"} transition duration-300`}>
                <span className="inline-block w-4 h-4 rounded-full border-2 border-red-600 mr-2"></span>
                Absent
              </Link>
            </div>

            <div>
              <Link
                onClick={() => setFilter("Below Threshold")}
                className={`px-2 py-2 text-lg rounded ${filter === "Below Threshold" ? "text-yellow-500" : "text-gray-800"} transition duration-300`}>
                <span className="inline-block w-4 h-4 rounded border-2 border-yellow-400 mr-2"></span>
                Below Threshold
              </Link>
            </div>
          </div>

          <div className="mt-6 max-h-[400px] overflow-y-auto rounded-lg w-full shadow-xl">
            <table className="min-w-full bg-white border">
              <thead className="bg-gray-100 text-gray-600 uppercase text-sm">
                <tr>
                  <th className="py-3 px-4 bg-gray-200 ">Employee Name
                    <button onClick={prevPage} disabled={currentPage === 0} className="lg:absolute  px-3 lg:top-[283px] text-2xl font-semibold text-green-600 rounded-full">
                      &lt;
                    </button>
                  </th>

                  {getFilteredDaysInRange().map((day) => (
                    <th key={day} className="py-3 px-4 text-center bg-gray-200">
                      {day + 1}
                    </th>
                  ))}
                  {/* Pagination controls */}
                  <th className="py-3 px-4 text-center bg-gray-200 border-slate-300 ">
                    <button onClick={nextPage} disabled={currentPage === totalPages - 1} className="px-3 lg:absolute lg:right-11 lg:top-[283px] text-2xl font-semibold text-green-600 rounded-full">
                      &gt;
                    </button>
                  </th>

                </tr>
              </thead>
              <tbody className="text-gray-700 text-sm">
                {filteredEmployees.map((employee) => (
                  <tr key={employee.emp_id} className="border">
                    <td className="py-3 px-6 font-medium sticky left-0 bg-white z-10 border">
                      {employee.name}
                    </td>
                    {getFilteredDaysInRange().map((day) => (
                      <td key={day} className="py-3 px-4 text-center border">
                        {filteredAttendance(employee.attendance[day]) ? (
                          employee.attendance[day] >= attendanceThreshold ? (
                            <span className="inline-block w-4 h-4 bg-green-300 rounded-full"></span>
                          ) : employee.attendance[day] > 0 ? (
                            <span className="inline-block w-4 h-4 rounded border-2 border-yellow-400"></span>
                          ) : (
                            <span className="inline-block w-4 h-4 rounded-full border-2 border-red-600"></span>
                          )
                        ) : (
                          <span className="inline-block w-4 h-4 rounded-full bg-gray-300"></span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_attendance;
