import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Modal from '../../components/model/Modal';
import UpdateEmployee from '../../components/Employee/Update_employee'; // Import UpdateEmployee
import { FaSearch, FaRegEdit } from "react-icons/fa";
import { PiFileCsv } from "react-icons/pi";
import { MdAdd } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddEmployee from '../../components/Employee/Add_employee';
import { GetAllEmployee } from '../../components/ApiBaseUrl';
import { IoClose } from 'react-icons/io5';

const ManageEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddEmployeeOpen, setIsAddEmployeOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const employeesPerPage = 10;
  const toggleModal = () => setIsOpen(!isOpen);
  const toggleAddemployee = () => setIsAddEmployeOpen(!isAddEmployeeOpen)
  useEffect(() => {
    const fetchallemployee = async () => {
      // fetch('/employeesData.json')
      //   .then((response) => response.json())
      //   .then((data) => {
      //     setEmployees(data.employees);
      //   })
      //   .catch((error) => console.error('Error fetching leave requests:', error));
      try {
        const response = await GetAllEmployee("getallemployee");
        console.log(response.data)
        setEmployees(response.data);
      } catch (err) {
        console.log("Error : ", err);
        setEmployees([]);
      }
    };
    fetchallemployee();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDeleteEmployee = () => {
    setDeleteModal(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleEmployeeUpdate = (updatedEmployee) => {
    setEmployees(employees.map(emp => emp.user_id === updatedEmployee.user_id ? updatedEmployee : emp));
    console.log("Updated employee data:", updatedEmployee);
  };

  // Filter employees based on Search Input
  const filteredEmployees = employees.filter((employee) =>
    employee.first_name?.includes(searchTerm) ||
    employee.phone?.includes(searchTerm) ||
    employee.email?.includes(searchTerm)
  );

  // Pagination Logic
  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);
  const totalPages = Math.ceil(filteredEmployees.length / employeesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar className="fixed h-full" />
      <div className="flex-1 bg-white divide-y overflow-auto">
        <Navbar pageTitle="Manage Employees" />
        <div className="flex items-start justify-start bg-white h-auto">
          <div className="container mx-auto p-6">
            {isOpen && (
              <div className="fixed inset-0 flex items-start pt-6 pr-6 justify-end bg-black bg-opacity-50 z-50">
                <div className="bg-white text-white p-6 rounded-lg w-1/4 relative">
                  {/* Close Button */}
                  <button
                    onClick={() => setIsOpen(false)}
                    className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
                  >
                    <IoClose size={24} />
                  </button>

                  {/* Modal Header */}
                  <h2 className="text-lg text-black font-semibold mb-4 border-b border-gray-400 pb-2">
                    Import
                  </h2>

                  {/* Modal Content */}
                  <div className="bg-gray-200 rounded-lg px-4 py-2">
                    <p className="text-sm text-black mb-4">
                      Import allows max 100 employees at once.<br />
                      Support .csv, .xls, and .xlsx file formats.
                    </p>


                    {/* File Chooser Placeholder */}
                    <label className="text-gray-500 py-6 rounded-lg text-center mb-2 border border-gray-300 block cursor-pointer">
                      {selectedFile ? selectedFile.name : "No file chosen"}
                      <input
                        type="file"
                        accept=".csv, .xls, .xlsx"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>

                    {/* Download Link */}
                    <a
                      href="/path-to-sample.csv"
                      className="text-sm text-blue-500 underline flex items-center justify-start"
                      download
                    >
                      <BsDownload className="mr-2" />
                      Download sample CSV file
                    </a>
                  </div>

                  {/* Centered Upload Button */}
                  <div className="flex justify-center mt-4">
                    <button className="border border-green-700 text-green-700 py-1 px-4 rounded-lg bg-green-200">
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className='flex'>
              {/* Employee Table Card */}
              <div className="overflow-x-auto mb-6 w-2/3 border-2 border-gray-200 h-auto rounded-lg">
                <div className='py-2 px-4'>
                  {/* Search Input */}
                  <div className="flex items-center mb-4 border rounded-md  border-gray-300">
                    <FaSearch className='text-gray-500 mx-2' size={16} />
                    <input
                      type="text"
                      className="py-2  w-full rounded-md focus:outline-none"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className='text-gray-500'>
                    Showing <label className='bg-green-100 rounded py-0 px-1 text-green-700'>1-10 of 10</label> entries
                  </div>
                  <div className="bg-white rounded-lg mt-4">
                    <table className="min-w-full bg-white rounded-lg">
                      <thead>
                        <tr className="bg-gray-100 text-gray-600 text-sm leading-normal">
                          <th className="py-3 pl-4 text-left">Id</th>
                          <th className="py-3 px-4 text-left">Name</th>
                          {/* <th className="py-3 px-4 text-left">Department</th> */}
                          <th className="py-3 px-4 text-left">Contact Number</th>
                          <th className="py-3 px-4 text-left">Email</th>
                          <th className="py-3 px-4 text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm font-light">
                        {currentEmployees.length > 0 ? (
                          currentEmployees.map((employee, index) => (
                            <tr
                              key={employee.user_id}
                              className={`border-b border-gray-200 hover:bg-gray-50 ${selectedEmployee?.user_id === employee.user_id ? '' : ''
                                }`}
                              onClick={() => setSelectedEmployee(employee)}
                            >
                              <td className="py-3 pl-4 text-left whitespace-nowrap">
                                <span className="font-medium">{indexOfFirstEmployee + index + 1}</span>
                              </td>
                              <td className="py-3 px-4 text-left whitespace-nowrap">
                                <span className="font-medium">{employee.first_name}</span>
                              </td>
                              {/* <td className="py-3 px-4 text-left whitespace-nowrap">
                                    <span className="font-medium">{employee.department}</span>
                                  </td> */}
                              <td className="py-3 px-4 text-left">
                                <span className="font-medium">{employee.phone}</span>
                              </td>
                              <td className="py-3 px-4 text-left">
                                <span className="font-medium">{employee.email}</span>
                              </td>
                              <td className="py-3 px-4 text-left flex space-x-2">
                                {/* Edit Button */}
                                <button
                                  className="p-2 rounded bg-green-50 focus:outline-none"
                                  aria-label="Edit"
                                >
                                  <FaRegEdit className="text-green-600" size={16} />
                                </button>

                                {/* Delete Button */}
                                <button
                                  className="p-2 rounded bg-red-50 focus:outline-none"
                                  aria-label="Delete"
                                  onClick={() => handleDeleteEmployee(employee.user_id)}
                                >
                                  <RiDeleteBin6Line className="text-red-600" size={17} />
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center py-4">
                              No employees found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                      <div className={`flex justify-between mt-4 ${currentPage === 1 && currentPage === totalPages ? "opacity-50 pointer-events-none" : ""}`}>
                        <button
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                          className={`px-4 py-2 bg-gray-300 text-gray-800 rounded ${currentPage === 1 && "opacity-50 cursor-not-allowed"}`}
                        >
                          Previous
                        </button>
                        <span className="text-gray-600">Page {currentPage} of {totalPages}</span>
                        <button
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          className={`px-4 py-2 bg-gray-300 text-gray-800 rounded ${currentPage === totalPages && "opacity-50 cursor-not-allowed"}`}
                        >
                          Next
                        </button>
                      </div>
                    )}

                  </div>
                </div>
              </div>

              {/* Employee Details Card */}

              <div className="bg-white ml-4 w-1/3">
                <div className='flex mb-4 justify-stretch space-x-4'>
                  <label
                    className="w-full border-2 border-gray-200 bg-green-100 text-green-700 font-semibold py-2 px-4 rounded-lg cursor-pointer flex items-center space-x-2"
                    onClick={toggleAddemployee}
                  >
                    <MdAdd className="text-white-400" size={24} />
                    <span>Add Employee</span>
                  </label>
                  <label
                    htmlFor="upload"
                    className="w-full border-2 border-gray-200 bg-green-100 text-green-700 font-semibold py-2 px-4 rounded-lg cursor-pointer flex items-center space-x-2"
                    onClick={toggleModal}
                  >
                    <PiFileCsv className="text-white-400" size={24} />
                    <span>Upload Excel</span>
                  </label>
                </div>
                {selectedEmployee && (
                  <motion.div
                    key={selectedEmployee.id} // Unique key to trigger re-render on data change
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 2, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 1 }}
                    className="p-6 border-2 border-gray-200 rounded-lg bg-white w-full max-w-lg mx-auto"
                  >
                    {/* Profile Picture */}
                    <div className="flex flex-col items-center mb-6">
                      <img
                        src={selectedEmployee.profilePicture || "/asset/profile_pic_1.jpg"}
                        alt="Profile"
                        className="w-24 h-24 rounded-full border-2 border-gray-300 object-cover mb-4"
                      />
                      <h2 className="text-xl font-semibold text-gray-700">{selectedEmployee.first_name}</h2>
                    </div>

                    {/* Employee Details */}
                    <div className="space-y-3 px-1">
                      {[
                        { label: 'Contact', value: selectedEmployee.phone },
                        { label: 'Email', value: selectedEmployee.email },
                        { label: 'Date of Birth', value: selectedEmployee.date_of_birth },
                        { label: 'Address', value: selectedEmployee.address.city },
                        { label: 'Department', value: selectedEmployee.department },
                        { label: 'Job Title', value: selectedEmployee.job_title },
                        { label: 'Joining Date', value: selectedEmployee.date_of_joining },
                      ].map((detail, index) => (
                        <div key={index} className="flex items-center py-1 border-b last:border-b-0 border-gray-200">
                          <div className="flex items-center text-gray-500 w-1/3">
                            <span className="font-medium">{detail.label}:</span>
                          </div>
                          <span className="text-gray-700 flex-1">{detail.value}</span>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}

              </div>
            </div>

            {isAddEmployeeOpen && (
              <div className="fixed inset-0 flex items-start py-6 justify-center bg-black bg-opacity-50 z-50">
                <AddEmployee close={() => setIsAddEmployeOpen(false)} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for Updating Employee */}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <UpdateEmployee employee={selectedEmployee} onUpdate={handleEmployeeUpdate} />
        </Modal>
      )}

      {deleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.6 }}
            className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto text-start"
          >
            <h2 className="text-xl font-bold mb-4">Alert!</h2>
            <p className="mt-2">Are you sure you want to delete this employee?</p>
            <div className="mt-8 flex justify-center space-x-16">
              <button
                onClick={() => setDeleteModal(false)}
                className="px-3 py-1 bg-green-100 text-green-700 rounded-lg transition-colors"
              >
                Yes
              </button>
              <button
                onClick={() => setDeleteModal(false)}
                className="px-3 py-1 bg-red-100 text-red-700 rounded-lg transition-colors"
              >
                No
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default ManageEmployees;
