import { useNavigate } from "react-router-dom";

const EmployeesSummary = () => {
    const navigate = useNavigate();
    const handleViewEmployeesClick = () => {
        // Navigate to ManageEmployees page
        navigate("/manageemployees");
    };
    return (
        <div className=" max-w-lg bg-white p-4 rounded-xl shadow-lg text-center">
            <div className="flex justify-between items-center mb-9">
                <div className="text-base lg:text-lg font-semibold">Employees Summary</div>
            </div>
            <div className="flex justify-between mb-6">
                <div className="flex flex-col">
                    <span className="text-2xl font-semibold text-green-500">180</span> {/* Active employees Count */}
                    <span className="text-xs text-gray-500">Active Employees</span>
                </div>
                <div className="flex flex-col">
                    <span className="text-2xl font-semibold text-gray-800">200</span> {/* Total employees Count */}
                    <span className="text-xs text-gray-500">Total Employees</span>
                </div>
            </div>
            <span
                onClick={handleViewEmployeesClick}
                className="text-xs text-blue-600 hover:underline cursor-pointer"
            >
                View Employees
            </span> {/* Link */}
        </div>
    );
}

export default EmployeesSummary;