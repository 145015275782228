import React, { useState,useEffect } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { GetRolesApi } from '../ApiBaseUrl';

const EmploymentDetails = ({ formData, onFormDataChange }) => {
    const [roles, setRoles] = useState([]);
    const [employeeId, setEmployeeId] = useState(formData.employeeId || '');
    const [jobTitle, setJobTitle] = useState(formData.jobTitle || '');
    // const [department, setDepartment] = useState(formData.department || '');
    const [salary, setSalary] = useState(formData.salary || '');
    const [dateOfJoining, setDateOfJoining] = useState(formData.date_of_joining || '');
    const [employmentStatus, setEmploymentStatus] = useState(formData.employment_status || 'Full-time');
    const [contractType, setContractType] = useState(formData.contract_type || 'Permanent');
    const [workLocation, setWorkLocation] = useState(formData.work_location || 'Remote');
    const [workShift, setWorkShift] = useState(formData.work_shift || 'Day');
    const [bankaccountname, setbankaccountname] = useState(formData.bankaccountname || '');
    const [bankaccountnumber, setbankaccountnumber] = useState(formData.bankaccountnumber || '');
    // const [terminationReason, setTerminationReason] = useState(formData.termination_reason || '');
    const [contractorName, setContractorName] = useState(formData.contractor_name || ''); // New state for contractor name
    const [selectedRoleIds, setSelectedRoleIds] = useState(formData.role || []);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleChange = (field, value) => {
        switch (field) {
            case 'employeeId':
                setEmployeeId(value);
                break;
            case 'jobTitle':
                setJobTitle(value);
                break;
            // case 'department':
            //     setDepartment(value);
            //     break;
            case 'salary':
                setSalary(value);
                break;
            case 'dateOfJoining':
                setDateOfJoining(value);
                break;
            case 'employmentStatus':
                setEmploymentStatus(value);
                break;
            case 'contractType':
                setContractType(value);
                break;
            case 'workLocation':
                setWorkLocation(value);
                break;
            case 'workShift':
                setWorkShift(value);
                break;
            case 'bankaccountname':
                setbankaccountname(value);
                break;
            case 'bankaccountnumber':
                setbankaccountnumber(value);
                break;
            // case 'terminationReason':
            //     setTerminationReason(value);
            //     break;
            case 'contractorName': // New case for contractor name
                setContractorName(value);
                break;
            case 'role': // New case for role
                setSelectedRoleIds(value);
                break;
            default:
                break;
        }

        // Directly call onFormDataChange whenever a field changes
        onFormDataChange({
            employeeId, 
            jobTitle, 
            // department,
            salary,
            dateOfJoining,
            employmentStatus,
            contractType,  
            workLocation,
            workShift,
            bankaccountname,
            bankaccountnumber,
            // terminationReason,
            contractorName,
         role: selectedRoleIds, // Include contractor name
        });
    };


    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await GetRolesApi("roles");
                console.log(response.data)
                setRoles(response.data);
            } catch (err) {
                console.log("Error : ", err);
                setRoles([]);
            }
        };
        fetchRoles(); // Call the function to fetch roles
    }, []);

   

    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleRoleChange = (role_id) => {
        setSelectedRoleIds(prevSelected => {
            if (prevSelected.includes(role_id)) {
                return prevSelected.filter(id => id !== role_id);
            } else {
                return [...prevSelected, role_id];
            }
        });
    };

   

    return (
        <div className="step-content grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <label htmlFor="employeeId" className="block text-gray-700">Employee Id<span className="text-red-500">*</span></label>
                <input
                    id="employeeId"
                    type="text" // Keep type as "text" to handle custom validation
                    value={employeeId}
                    onChange={(e) => {
                        // Ensure only numbers and limit input to 6 digits
                        const value = e.target.value;
                        if (/^\d{0,6}$/.test(value)) {
                            handleChange('employeeId', value); // Update state only if it's a valid number
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="Id"
                    maxLength={6} // Limit input to 6 digits
                    required
                />
                {/* Error Message */}
                {employeeId && (employeeId.length > 6 || !/^\d*$/.test(employeeId)) && (
                    <p className="text-red-500 text-sm mt-1">Employee ID must be a 6-digit number.</p>
                )}
            </div>

            <div>
                <label htmlFor="jobTitle" className="block text-gray-700">Job Title</label>
                <input
                    id="jobTitle"
                    type="text"
                    value={jobTitle}
                    onChange={(e) => {
                        // Only allow alphabets and spaces
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                            handleChange('jobTitle', value); // Update state if valid
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="job title"
                />
                {/* Error Message */}
                {jobTitle && !/^[A-Za-z\s]*$/.test(jobTitle) && (
                    <p className="text-red-500 text-sm mt-1">Job title can only contain alphabets and spaces.</p>
                )}
            </div>

            {/* <div>
                <label htmlFor="department" className="block text-gray-700">
                    Department<span className="text-red-500">*</span>
                </label>
                <select
                    id="department"
                    value={department}
                    onChange={(e) => handleChange('department', parseInt(e.target.value))}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1 text-gray-500"
                    required
                >
                    <option value="" disabled>Select a department</option>
                    <option value={1}>HR</option>
                    <option value={2}>Finance</option>
                    <option value={3}>Marketing</option>
                    <option value={4}>IT</option>
                    <option value={5}>Operations</option>
                </select>
            </div> */}

            {/* Role Dropdown */}
            <div className="relative">
                <label htmlFor="role" className="block text-gray-700">
                    Role
                </label>
                <div onClick={toggleDropdown} className="cursor-pointer border border-gray-300 rounded px-4 py-2 mt-1 flex justify-between items-center">
                    <span>
                        {selectedRoleIds.length > 0
                            ? selectedRoleIds.map(id => roles.find(role => role.role_id === id).role_name).join(', ')
                            : 'Select roles'}
                    </span>
                    {isDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                {isDropdownOpen && (
                    <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg">
                        <div className="p-2">
                            {roles && roles.map(({ role_id, role_name }) => (
                                <div key={role_id} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id={role_name && role_name}
                                        value={role_id}
                                        checked={selectedRoleIds.includes(role_id)}
                                        onChange={() => handleRoleChange(role_id)}
                                        className="mr-2"
                                    />
                                    <label htmlFor={role_name} className="text-gray-700">{role_name}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div>
                <label htmlFor="dateOfJoining" className="block text-gray-700">
                    Date of Joining 
                </label>
                <input
                    id="dateOfJoining"
                    type="date"
                    value={dateOfJoining}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => handleChange('dateOfJoining', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="joining date"
                />
            </div>


            <div>
                <label htmlFor="contractType" className="block text-gray-700">Job Type</label>
                <select
                    id="contractType"
                    value={contractType}
                    onChange={(e) => handleChange('contractType', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                >
                    <option value="Permanent">Permanent</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Freelance">Freelance</option>
                </select>
            </div>

            <div>
                <label htmlFor="employmentStatus" className="block text-gray-700">Employment Status</label>
                <select
                    id="employmentStatus"
                    value={employmentStatus}
                    onChange={(e) => handleChange('employmentStatus', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                >
                    <option value="Full-time">Full-time</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Contract">Contract</option>
                </select>
            </div>

            {employmentStatus === "Contract" && (
                <div>
                    <label htmlFor="contractorName" className="block text-gray-700">
                        Contractor Name
                    </label>
                    <input
                        id="contractorName"
                        type="text"
                        value={contractorName}
                        onChange={(e) => handleChange('contractorName', e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                        placeholder="contractor name"
                    />
                </div>
            )}

            <div>
                <label htmlFor="workLocation" className="block text-gray-700">Work Location</label>
                <input
                    id="workLocation"
                    type="text"
                    value={workLocation}
                    onChange={(e) => handleChange('workLocation', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="work location"
                />
            </div>

            <div>
                <label htmlFor="workShift" className="block text-gray-700">Work Shift</label>
                <select
                    id="workShift"
                    value={workShift}
                    onChange={(e) => handleChange('workShift', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                >
                    <option value="Day">Day</option>
                    <option value="Night">Night</option>
                    <option value="Rotational">Rotational</option>
                </select>
            </div>

            <div>
                <label htmlFor="bankaccountname" className="block text-gray-700">Bank Account Name</label>
                <input
                    id="bankaccountname"
                    type="text"
                    value={bankaccountname}
                    onChange={(e) => {
                        // Only allow alphabets and spaces
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                            handleChange('bankaccountname', value); // Update state if valid
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="bank account holder name"
                />
                {/* Error Message */}
                {bankaccountname && !/^[A-Za-z\s]*$/.test(bankaccountname) && (
                    <p className="text-red-500 text-sm mt-1">Bank account name can only contain alphabets and spaces.</p>
                )}
            </div>

            <div>
                <label htmlFor="bankaccountnumber" className="block text-gray-700">Bank Account Number</label>
                <input
                    id="bankaccountnumber"
                    type="text"
                    value={bankaccountnumber}
                    onChange={(e) => {
                        // Only allow numbers and limit to 15 digits
                        const value = e.target.value;
                        // Allow only digits and limit to 15 characters
                        if (/^\d{0,15}$/.test(value)) {
                            handleChange('bankaccountnumber', value); // Update state if valid
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="bank account number"
                    maxLength={15} // Limit input length to 15 digits
                />
                {/* Error Message */}
                {bankaccountnumber && !/^\d{0,15}$/.test(bankaccountnumber) && (
                    <p className="text-red-500 text-sm mt-1">Bank account number must be a 15-digit number.</p>
                )}
            </div>

            {/* <div>
                <label htmlFor="terminationReason" className="block text-gray-700">Termination Reason (if applicable)<span className="text-red-500">*</span></label>
                <input
                    id="terminationReason"
                    type="text"
                    value={terminationReason}
                    onChange={(e) => handleChange('terminationReason', e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="termination reason (if applicable)"
                    required
                />
            </div> */}
        </div>
    );
};

export default EmploymentDetails;
