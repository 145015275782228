import React, { useState, useEffect, useRef } from 'react';
import { FaEnvelope,FaTimes } from "react-icons/fa";

const UpdateEmployee = ({ employeeId, onClose }) => {
    const [step, setStep] = useState(1);
    const [employeeData, setEmployeeData] = useState(null);
    const [jobTime, setJobTime] = useState(""); 
    const formRef = useRef(null);

    useEffect(() => {
        const fetchEmployeeData = async () => {
            const employee = {
                name: "John Doe",
                email: "john.doe@example.com",
                address: "123 Main St",
                contactNumber: "1234567890",
                department: "IT",
                manager: "Jane Smith",
                jobTime: "Full Time",
                contract: "",
                salary: 50000,
                basic: 30000,
                houseRentAllowance: 5000,
                conveyanceAllowance: 2000,
                transportAllowance: 1000,
                foodAllowance: 1500,
                internetAllowance: 800,
                fixedAllowance: 1000,
                ptDeduction: 100,
                pfDeduction: 500,
                insuranceDeduction: 300,
                otherDeductions: 100
            };
            setEmployeeData(employee);
            setJobTime(employee.jobTime);
        };
        fetchEmployeeData();
    }, [employeeId]);

    const nextStep = () => {
        if (formRef.current.checkValidity()) {
            setStep(step + 1);
        } else {
            formRef.current.reportValidity();
        }
    };

    const handleSubmit = () => {
        if (formRef.current.checkValidity()) {
            console.log("Employee updated", employeeData);
        } else {
            formRef.current.reportValidity();
        }
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    if (!employeeData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="modal-content" style={{ width: '900px', height: 'auto', maxHeight: '90vh', overflowY: 'auto', position: 'relative' }}>
            {/* Close Button */}
            <button className="absolute top-4 right-4 text-gray-400 hover:text-red-600" onClick={onClose}>
                <FaTimes size={20} />
            </button>

            <div className="flex items-start justify-start bg-white h-full">
                <div className="w-full h-fit bg-white p-4 sm:p-4" style={{ minHeight: '500px' }}>
                    <h1 className="text-xl font-semibold text-blue-900 text-center">Update Employee Details</h1>
                    
                    {/* Step Indicators */}
                    <div className="flex justify-between items-center mt-4 mb-8">
                        <div className={`step ${step === 1 ? "active" : ""}`}>
                            <span className="step-number">1</span>
                            <span className="step-label">Basic Details</span>
                        </div>
                        <div className="flex-grow h-1 bg-gray-300 mx-2 relative">
                            <div className="absolute top-0 left-0 h-1 bg-blue-500 transition-all duration-300"
                                 style={{ width: `${step >= 2 ? 100 : 0}%` }}/>
                        </div>
                        <div className={`step ${step === 2 ? "active" : ""}`}>
                            <span className="step-number">2</span>
                            <span className="step-label">Payroll Details</span>
                        </div>
                        <div className="flex-grow h-1 bg-gray-300 mx-2 relative">
                            <div className="absolute top-0 left-0 h-1 bg-blue-500 transition-all duration-300"
                                 style={{ width: `${step === 3 ? 100 : 0}%` }}/>
                        </div>
                        <div className={`step ${step === 3 ? "active" : ""}`}>
                            <span className="step-number">3</span>
                            <span className="step-label">Deduction / Investment</span>
                        </div>
                    </div>

                    {/* Form Fields */}
                    <form ref={formRef}>
                        {step === 1 && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700">Name <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.name}
                                        onChange={(e) => setEmployeeData({ ...employeeData, name: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Email id </label>
                                    <div className="relative">
                                        <input
                                            type="email"
                                            className="w-full p-2 border border-gray-300 rounded mt-1"
                                            value={employeeData.email}
                                            onChange={(e) => setEmployeeData({ ...employeeData, email: e.target.value })}
                                        />
                                        <FaEnvelope className="absolute right-3 top-4 text-gray-400" />
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-gray-700">Address <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.address}
                                        onChange={(e) => setEmployeeData({ ...employeeData, address: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Contact Number <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.contactNumber}
                                        onChange={(e) => setEmployeeData({ ...employeeData, contactNumber: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Department <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.department}
                                        onChange={(e) => setEmployeeData({ ...employeeData, department: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Manager <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.manager}
                                        onChange={(e) => setEmployeeData({ ...employeeData, manager: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Job Time <span className="text-red-500">*</span></label>
                                    <div className="flex space-x-4 mt-2">
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="jobtime"
                                                className="mr-2"
                                                value="Part Time"
                                                checked={jobTime === "Part Time"}
                                                onChange={(e) => setJobTime(e.target.value)}
                                                required
                                            /> Part Time
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="jobtime"
                                                className="mr-2"
                                                value="Full Time"
                                                checked={jobTime === "Full Time"}
                                                onChange={(e) => setJobTime(e.target.value)}
                                                required
                                            /> Full Time
                                        </label>
                                        <label className="flex items-center">
                                            <input
                                                type="radio"
                                                name="jobtime"
                                                className="mr-2"
                                                value="Contract"
                                                checked={jobTime === "Contract"}
                                                onChange={(e) => setJobTime(e.target.value)}
                                                required
                                            /> Contract
                                        </label>
                                    </div>
                                </div>
                                {jobTime === "Contract" && (
                                    <div>
                                        <label className="block text-gray-700">Contract <span className="text-red-500">*</span></label>
                                        <input
                                            type="text"
                                            className="w-full p-2 border border-gray-300 rounded mt-1"
                                            value={employeeData.contract}
                                            onChange={(e) => setEmployeeData({ ...employeeData, contract: e.target.value })}
                                            required
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Step 2 */}
                        {step === 2 && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700">Salary <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.salary}
                                        onChange={(e) => setEmployeeData({ ...employeeData, salary: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Basic <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.basic}
                                        onChange={(e) => setEmployeeData({ ...employeeData, basic: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">House Rent Allowance <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.houseRentAllowance}
                                        onChange={(e) => setEmployeeData({ ...employeeData, houseRentAllowance: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Conveyance Allowance <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.conveyanceAllowance}
                                        onChange={(e) => setEmployeeData({ ...employeeData, conveyanceAllowance: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Transport Allowance <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.transportAllowance}
                                        onChange={(e) => setEmployeeData({ ...employeeData, transportAllowance: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Food Allowance <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.foodAllowance}
                                        onChange={(e) => setEmployeeData({ ...employeeData, foodAllowance: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Internet Allowance <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.internetAllowance}
                                        onChange={(e) => setEmployeeData({ ...employeeData, internetAllowance: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Fixed Allowance <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.fixedAllowance}
                                        onChange={(e) => setEmployeeData({ ...employeeData, fixedAllowance: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>
                        )}

                        {/* Step 3 */}
                        {step === 3 && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700">PT Deduction <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.ptDeduction}
                                        onChange={(e) => setEmployeeData({ ...employeeData, ptDeduction: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">PF Deduction <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.pfDeduction}
                                        onChange={(e) => setEmployeeData({ ...employeeData, pfDeduction: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Insurance Deduction <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.insuranceDeduction}
                                        onChange={(e) => setEmployeeData({ ...employeeData, insuranceDeduction: e.target.value })}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700">Other Deductions <span className="text-red-500">*</span></label>
                                    <input
                                        type="number"
                                        className="w-full p-2 border border-gray-300 rounded mt-1"
                                        value={employeeData.otherDeductions}
                                        onChange={(e) => setEmployeeData({ ...employeeData, otherDeductions: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>
                        )}
                    </form>

                    {/* Navigation Buttons */}
                    <div className={`mt-8 flex ${step === 1 ? 'justify-end' : 'justify-between'}`}>
                        {step > 1 && (
                            <button
                                type="button"
                                className="bg-gray-500 text-white py-2 px-4 rounded"
                                onClick={prevStep}
                            >
                                Previous
                            </button>
                        )}
                        {step < 3 && (
                            <button
                                type="button"
                                className="bg-blue-500 text-white py-2 px-4 rounded"
                                onClick={nextStep}
                            >
                                Next
                            </button>
                        )}
                        {step === 3 && (
                            <button
                                type="button"
                                className="bg-green-500 text-white py-2 px-4 rounded"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateEmployee;
