import React from 'react';
import { FiArrowUp } from 'react-icons/fi'; // Importing the arrow icon

const payrollData = [
    { id: 1, name: 'Michelle Martin', date: 'September 23, 2024', amount: '₹3,200', status: 'Success', imageUrl: 'https://via.placeholder.com/30' },
    { id: 2, name: 'Jorge Pérez', date: 'September 23, 2024', amount: '₹5,000', status: 'Success', imageUrl: 'https://via.placeholder.com/30' },
    { id: 3, name: 'Priyanka Kumar', date: 'September 23, 2024', amount: '₹2,900', status: 'Delay', imageUrl: 'https://via.placeholder.com/30' },
    { id: 4, name: 'Arushi Choudhary', date: 'September 23, 2024', amount: '₹3,200', status: 'Delay', imageUrl: 'https://via.placeholder.com/30' },
    // { id: 5, name: 'Ruth Anderson', date: 'September 23, 2024', amount: '$2,300', status: 'Success', imageUrl: 'https://via.placeholder.com/30' },
];

const RecentPayroll = () => {
    return (
        <div className="max-w-sm bg-white rounded-lg shadow-xl p-4">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Recent Payroll</h3>
                <button className="p-2 bg-green-100 rounded-full">
                    {/* Replaced SVG with React Icon */}
                    <FiArrowUp className="text-green-500 rotate-45 w-5 h-5" />
                </button>
            </div>

            {/* payroll List */}
            <ul className="divide-y divide-gray-200">
                {payrollData.map((payroll) => (
                    <li key={payroll.id} className="flex items-center py-3">
                        {/* Image */}
                        <img src={payroll.imageUrl} alt={payroll.name} className="w-10 h-10 rounded-full mr-3" />
                        
                        {/* Employee Info */}
                        <div className="flex-grow">
                            <p className="text-sm font-semibold">{payroll.name}</p>
                            <p className="text-xs text-gray-500">{payroll.date}</p>
                        </div>
                        
                        {/* Amount and Status */}
                        <div className="text-right">
                            <p className="text-sm font-semibold">{payroll.amount}</p>
                            <p className={`text-xs font-semibold ${payroll.status === 'Success' ? 'text-green-500' : 'text-red-500'}`}>
                                {payroll.status}
                            </p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentPayroll;
