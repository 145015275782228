import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded shadow-lg p-4">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500">&times;</button>
        {children}
      </div>
    </div>,
    document.getElementById('portal-root') // Ensure this exists in your index.html
  );
};

export default Modal;
