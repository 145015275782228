import React from 'react';
import { FaFileInvoiceDollar, FaFileAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const Tasks = () => {
    const navigate = useNavigate();

    const handleApproveClick = () => {
        navigate("/viewleaverequests"); // Navigate to View leave Requests page
    };


    return (
        <div className="bg-white px-6 pb-6">
            <h3 className="text-lg font-semibold mb-4 mt-8">Task for Approval</h3>
            <div className="flex justify-between items-center py-4 border-b">
                <div className="flex items-start">
                    <FaFileAlt className="h-10 w-10 text-gray-600 mr-4 border border-gray-300 p-2 rounded-full" /> {/* Icon */}
                    <div>
                        <h4 className="font-normal">
                            96 <span className="font-semibold">Leave(s)</span>
                        </h4>
                        <p className="text-sm text-gray-500">Pending Approval</p>
                        <button onClick={handleApproveClick} className="bg-green-100 text-green-600 text-sm px-4 py-1 mt-4 rounded border border-green-400">Approve</button>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center py-4">
                <div className="flex items-start">
                    <FaFileInvoiceDollar className="border rounded-full p-2 l h-10 w-10 border-gray-300 text-gray-600 mr-4" /> {/* Icon */}
                    <div>
                        <h4 className="font-normal">
                            136 <span className="font-semibold">Reimbursement Claim(s)</span>
                        </h4>
                        <p className="text-sm text-gray-500">Pending Approval</p>
                        <button className="bg-green-100 text-green-600 text-sm px-4 py-1 mt-4 rounded border border-green-400">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tasks;
