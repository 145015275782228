import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ForgetPassword from "../components/ForgetPassword";
import { LoginApi } from "../components/ApiBaseUrl";

function Login() {
  const [isDomainInput, setIsDomainInput] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [domain, setDomain] = useState(""); // Domain state
  const [email, setEmail] = useState(""); // Email state
  const [password, setPassword] = useState(""); // Password state
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for API call
  const emailRef = useRef(null); // Create a ref for email input

  const navigate = useNavigate();

  useEffect(() => {
    if (!isDomainInput) {
      emailRef.current?.focus(); // Focus on email input when switching from domain
    }
  }, [isDomainInput]);

  const handleContinue = (e) => {
    e.preventDefault();
    setIsDomainInput(false); // Proceed to the email and password input form
  };

  const handleBack = () => {
    setIsDomainInput(true); // Go back to domain input form
    setErrorMessage(""); // Reset error message
  };

  const togglePopup = () => setIsPopupOpen(!isPopupOpen); // Toggle "Forget Password" popup

  const handleSubmit = (email) => {
    console.log("Email submitted:", email);
    setIsPopupOpen(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

   const hostHeader = `${domain}.neohrms.in`;

    // Encode the form data as URL-encoded
    const formData = new URLSearchParams();
    formData.append("username", email);
    formData.append("password", password);

    try {
      const responseData = await LoginApi(
        "POST",
        "login",
        formData,
        hostHeader
      );
      if (responseData.status === 200) {
        console.log("Login successful:", responseData);
        localStorage.setItem("username", responseData.data.data.admin_name);
        navigate("/dashboard");
      } else {
        console.log("Login failed. Please check your credentials.");
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Login error:", error.message);
      setErrorMessage(error.message);
    } finally {
      setLoading(false); // Stop loading after the API call finishes
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      {/* Login Card */}
      <div className="bg-white shadow-md p-6 md:p-10 w-11/12 md:w-1/3 lg:w-1/3 text-center">
        {/* Logo */}
        <h1 className="text-3xl font-bold text-green-600 mb-10">NEOHRMS</h1>

        {/* Login Form */}
        <div className="2xl:px-20">
          <form onSubmit={isDomainInput ? handleContinue : handleLogin}>
            {isDomainInput ? (
              <>
                <label className="block text-left mb-4 text-gray-600 font-medium">
                  Enter your NEOHRMS domain name:
                </label>
                <div className="flex items-center border border-gray-300 rounded overflow-hidden mb-4">
                  <input
                    type="text"
                    placeholder="companydomain"
                    value={domain} // Controlled input for domain
                    onChange={(e) => setDomain(e.target.value)} // Update domain state
                    className="px-4 py-2 w-full focus:outline-none"
                    required
                  />
                  <span className="bg-gray-200 px-4 py-2 text-gray-600">
                    .neohrms.in
                  </span>
                </div>
                <button
                  type="submit"
                  className="bg-green-500 text-white py-2 mb-3 px-4 w-full rounded hover:bg-green-700 transition"
                >
                  Continue
                </button>
              </>
            ) : (
              <>
                <div>
                  <input
                    type="email"
                    placeholder="Email"
                    ref={emailRef} // Attach the ref to email input
                    value={email} // Controlled input for email
                    onChange={(e) => setEmail(e.target.value)} // Update email state
                    className="border border-gray-300 rounded px-4 py-2 w-full mb-4 focus:outline-none"
                    required
                  />
                </div>
                <div className="relative mb-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password} // Controlled input for password
                    onChange={(e) => setPassword(e.target.value)} // Update password state
                    className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none"
                    required
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="text-gray-600"
                    />
                  </button>
                </div>
                <div className="text-red-500 text-sm pb-2">{errorMessage}</div>
                <button
                  type="submit"
                  className={`bg-green-500 text-white py-2 px-4 w-full rounded transition ${
                    loading ? "opacity-50 cursor-not-allowed" : "hover:bg-green-700"
                  }`}
                  disabled={loading} // Disable button while loading
                >
                  {/* This div is used to center the text and spinner */}
                  <div className="flex items-center justify-center">
                    {loading && (
                      <span className="spinner-border w-5 h-5 border-t-2 border-white rounded-full animate-spin mr-2"></span>
                    )}
                    <span>{loading ? "Logging In..." : "Login"}</span>
                  </div>
                </button>
                <p className="mt-3 text-sm text-gray-600">
                  <span
                    className="hover:underline cursor-pointer"
                    onClick={handleBack}
                  >
                    Change domain name?
                  </span>
                </p>
                <p className="mt-3 text-sm text-gray-600">
                  <span
                    className="hover:underline cursor-pointer"
                    onClick={togglePopup}
                  >
                    Forget Password?
                  </span>
                </p>
              </>
            )}
          </form>
        </div>

        {/* Forget Password Modal */}
        <ForgetPassword
          isOpen={isPopupOpen}
          togglePopup={togglePopup}
          handleSubmit={handleSubmit}
        />
      </div>

      {/* Footer */}
      <div className="bottom-0 left-0 w-11/12 md:w-1/3 lg:w-1/3 bg-gradient-to-l from-green-600 to-green-200 h-0.5"></div>
      <div className="text-gray-500 text-sm mt-6">
        <span className="underline cursor-pointer">Privacy Policy</span> ·{" "}
        <span className="underline cursor-pointer">Terms of Service</span>
      </div>
    </div>
  );
}

export default Login;
