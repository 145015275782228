import React, { useState } from "react";

const ForgetPassword = ({ isOpen, togglePopup, handleSubmit }) => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(email);
    setEmail("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background overlay */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={togglePopup}
        style={{ zIndex: 10 }} // Set a lower z-index for the overlay
      ></div>

      {/* Modal content */}
      <div
        className="bg-white p-6 rounded-lg shadow-lg relative z-20 max-w-sm w-full mx-4 md:mx-0"
        style={{ zIndex: 20 }}
      >
        <h1 className="text-2xl font-bold mb-4 text-gray-800">Forget Password</h1>
        <p className="text-gray-600 mb-6">Enter your email address</p>

        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              type="email"
              value={email}
              placeholder="Enter your email"
              onChange={handleEmailChange}
              required
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-600"
            />
          </div>

          <button
            type="submit"
            className="bg-green-600 text-white py-2 px-4 w-full rounded-lg hover:bg-green-700 transition-colors"
          >
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
