import React, { useState } from "react";

const TotalPayrollexpenses = () => {
  const [currency] = useState("INR");

  const expenseData = {
    salaries: currency === "INR" ? "₹2,500K" : "$33K",
    bonuses: currency === "INR" ? "₹400K" : "$5K",
    other: currency === "INR" ? "₹146K" : "$2K",
    total: currency === "INR" ? "₹2,25,23,654.00" : "$40K",
  };

  return (
    <div className="max-w-lg p-4 bg-stone-100 rounded-lg shadow-xl content-between">
      {/* Header Section */}
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold">Total Payroll Expenses</h3>
        </div>
        <div>
          <button className="px-3 py-1 ml-2 mt-1 text-xs rounded-xl  bg-green-100 text-green-500 border border-green-400">
            Next
          </button>
        </div>
      </div>
      <div>
        <div className="text-2xl mt-9 font-semibold text-black uppercase">
          {expenseData.total}
        </div>
        <p className="text-gray-500 mt-2 text-sm">From 4-10 Sep, 2023</p>

        {/* Comparison Indicator */}
        <div className="flex items-center text-sm mt-4 text-green-500">
          <span className="mr-1">▲ 2.5%</span>
          <span>compared to last week</span>
        </div>
      </div>
    </div>
  );
};

export default TotalPayrollexpenses;
