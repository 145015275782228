import React, { useState } from 'react';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';

const LeavePolicies = () => {
    const [leaveTypes, setLeaveTypes] = useState([
        { id: 1, name: 'Annual Leave', entitlement: 20, carryOver: 5 },
        { id: 2, name: 'Sick Leave', entitlement: 15, carryOver: 3 },
        { id: 3, name: 'Casual Leave', entitlement: 10, carryOver: 2 },
    ]);

    const [newLeaveType, setNewLeaveType] = useState({ name: '', entitlement: '', carryOver: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewLeaveType({ ...newLeaveType, [name]: value });
    };

    const handleAddLeaveType = () => {
        if (newLeaveType.name && newLeaveType.entitlement && newLeaveType.carryOver) {
            setLeaveTypes([...leaveTypes, { ...newLeaveType, id: leaveTypes.length + 1 }]);
            setNewLeaveType({ name: '', entitlement: '', carryOver: '' });
        }
    };

    return (
        <div className="flex h-screen">
            <Sidebar className="fixed h-full" />
            <div className="flex-1 bg-white divide-y overflow-auto">
                <Navbar />
                <div className="container mx-auto p-4">
                    <h1 className="text-2xl font-semibold mb-4">Leave Policies</h1>
                    <div className="bg-white p-4 rounded-lg shadow-md mb-2">
                        <h2 className="text-xl font-semibold mb-2">Define/Edit Leave Types</h2>
                        <div className="flex flex-col mb-4">
                            <select
                                name="selectedLeaveType"
                                value={newLeaveType.selectedLeaveType}
                                onChange={handleInputChange}
                                className="border rounded w-full p-2 mb-2"
                            >
                                <option value="">Select leave type</option>
                                {leaveTypes.map((type) => (
                                    <option key={type.id} value={type.name}>{type.name}</option>
                                ))}
                            </select>
                            <label className="block text-sm font-medium mb-1">Entitlement (Days)</label>
                            <input
                                type="number"
                                name="entitlement"
                                value={newLeaveType.entitlement}
                                onChange={handleInputChange}
                                className="border rounded w-full p-2 mb-2"
                                placeholder="Enter entitlement days"
                            />
                            <label className="block text-sm font-medium mb-1">Carry Over (Days)</label>
                            <input
                                type="number"
                                name="carryOver"
                                value={newLeaveType.carryOver}
                                onChange={handleInputChange}
                                className="border rounded w-full p-2 mb-4"
                                placeholder="Enter carry over days"
                            />
                            <button
                                onClick={handleAddLeaveType}
                                className="bg-blue-500 text-white rounded px-4 py-2"
                            >
                                Add Leave Type
                            </button>
                        </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-2">Current Leave Types</h2>
                        <table className="min-w-full">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="px-4 py-2 text-left">Leave Type</th>
                                    <th className="px-4 py-2 text-left">Entitlement</th>
                                    <th className="px-4 py-2 text-left">Carry Over</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaveTypes.map((leaveType) => (
                                    <tr key={leaveType.id} className="border-b hover:bg-gray-100">
                                        <td className="px-4 py-2">{leaveType.name}</td>
                                        <td className="px-4 py-2">{leaveType.entitlement}</td>
                                        <td className="px-4 py-2">{leaveType.carryOver}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeavePolicies;
