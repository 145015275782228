import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  GetAllEmployee,
  GetLeaveRequests,
  LeaveRequestCreate,
} from "../../components/ApiBaseUrl";

const ViewLeaverequests = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("Pending");
  const [employees, setEmployees] = useState([]);
  const [days, setDays] = useState(0);
  // const [selectedDates, setSelectedDates] = useState([]);
  const [message, setMessage] = useState("");
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    // Fetch employees and leave requests
    const fetchAllEmployees = async () => {
      try {
        const response = await GetAllEmployee("getallemployee");
        setEmployees(response.data);
      } catch (err) {
        console.log("Error fetching employees:", err);
        setEmployees([]);
      }
    };

    const fetchLeaveRequests = async () => {
      try {
        const response = await GetLeaveRequests("getleaverequests");
        const requests = response.data?.data || [];
        setLeaveRequests(requests);
      } catch (err) {
        console.log("Error fetching leave requests:", err);
        setLeaveRequests([]);
      }
    };

    fetchAllEmployees();
    fetchLeaveRequests();
  }, []);

  const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  };

  const calculateDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleSelectEmployee = (employee) => {
    setSelectedRequest({ ...employee, startDate: "", endDate: "", reason: "" });
    setDays(0);
  };

  const handleSelectRequest = (request) => {
    const formattedStartDate = convertDateFormat(request.leave.start_date);
    const formattedEndDate = convertDateFormat(request.leave.end_date);
    setSelectedRequest({
      ...request,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    setDays(calculateDays(formattedStartDate, formattedEndDate));
  };

  // const handleDateChange = (date) => {
  //     const dateString = date.toDateString();
  //     if (selectedDates.includes(dateString)) {
  //         setSelectedDates(selectedDates.filter(d => d !== dateString));
  //     } else {
  //         setSelectedDates([...selectedDates, dateString]);
  //     }
  // };

  // const isSelected = (date) => selectedDates.includes(date.toDateString());

  const handleLeaveTypeChange = (event) => {
    setSelectedRequest((prevRequest) => ({
      ...prevRequest,
      leaveType: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const data = {
        user_id: selectedRequest.user_id,
        leaveType: selectedRequest.leaveType,
        startDate: selectedRequest.startDate,
        endDate: selectedRequest.endDate,
        reason: selectedRequest.reason,
      };

      const responseData = await LeaveRequestCreate(
        "POST",
        "leaverequestcreate",
        data
      );

      if (responseData.status === "success") {
        setMessage("Request submitted successfully!");
        setMessageType("success");
      } else {
        setMessage(responseData.message || "Failed to submit request.");
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Error submitting request.");
      setMessageType("error");
      console.error("Error submitting request:", error);
    } finally {
      setIsMessageVisible(true);
      setTimeout(() => setIsMessageVisible(false), 3000);
    }
  };

  const filteredRequests = Array.isArray(leaveRequests)
    ? leaveRequests.filter(
        (request) =>
          (request.user_first_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            request.leave.approval_status
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) &&
          (activeTab === "Pending"
            ? request.leave.approval_status === "pending"
            : request.leave.approval_status === "approved")
      )
    : [];

  const filteredEmployees = employees.filter((employee) =>
    employee.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // const leaveData = [
  //     { type: 'Annual Leave', balance: 23, used: 10 },
  //     { type: 'Sick Leave', balance: 15, used: 5 },
  //     { type: 'Casual Leave', balance: 8, used: 2 },
  // ];

  return (
    <div className="flex h-screen">
      <Sidebar className="fixed h-full" />
      <div className="flex-1 bg-white divide-y overflow-auto">
        <Navbar />
        <div className="flex flex-col lg:flex-row p-4 divide-x-2">
          {/* Leave Requests List */}
          <div className="lg:w-1/2 w-full bg-white p-4 lg:mr-4 overflow-auto">
            <h2 className="text-xl font-semibold mb-6">Leave Requests</h2>

            {/* Tabs */}
            <div className="flex mb-5">
              <div
                className={`mr-6 pb-1 cursor-pointer ${
                  activeTab === "Pending"
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("Pending")}
              >
                Pending
              </div>
              <div
                className={`pb-1 cursor-pointer ${
                  activeTab === "Approved"
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("Approved")}
              >
                Approved
              </div>
              <div
                className={`ml-6 pb-1 cursor-pointer ${
                  activeTab === "Raise_request"
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("Raise_request")}
              >
                Raise Request
              </div>
            </div>

            {/* Search Bar */}
            <input
              type="text"
              placeholder="Search by name"
              className="w-full p-2 mb-4 border rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Conditional Rendering for Tabs */}
            {activeTab === "Raise_request" ? (
              <div className="overflow-auto">
                <ul>
                  {filteredEmployees.map((employee) => (
                    <li
                      key={employee.user_id}
                      className="p-4 mb-2 border rounded-lg cursor-pointer"
                      onClick={() => handleSelectEmployee(employee)}
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium">
                          {employee.first_name}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <ul>
                {filteredRequests.map((request) => (
                  <li
                    key={request.leave.id}
                    className="p-4 mb-2 border rounded-lg cursor-pointer"
                    onClick={() => handleSelectRequest(request)}
                  >
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">
                        {request.user_first_name}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Request Details */}
          <div className="lg:w-2/3 w-full bg-white p-4 pl-8 pr-8">
            {selectedRequest ? (
              <div>
                <h3 className="text-xl font-semibold mb-6">Request Details</h3>
                <div className="bg-white rounded-lg">
                  <div className="flex">
                    <div className="flex-auto">
                      <div className="mb-4 flex items-center">
                        <img
                          className="h-10 w-10 rounded-full object-cover"
                          src="https://www.shutterstock.com/image-photo/closeup-portrait-young-smiling-hispanic-260nw-2327799157.jpg"
                          alt="User Avatar"
                        />
                        <label className="text-lg ml-4">
                          {selectedRequest.user_first_name || "Unknown"}
                        </label>
                      </div>
                      <div className="mb-4">
                        <label className="font-semibold">Leave Type:</label>
                        <select
                          value={
                            selectedRequest.leave?.leave_type || "Annual Leave"
                          }
                          onChange={handleLeaveTypeChange}
                          className="block w-full border rounded p-2"
                        >
                          <option value="Annual Leave">Annual Leave</option>
                          <option value="Sick Leave">Sick Leave</option>
                          <option value="Casual Leave">Casual Leave</option>
                        </select>
                      </div>
                      <div className="mb-4">
                        <label className="font-semibold">Reason:</label>
                        <textarea
                          className="block w-full border rounded p-2"
                          value={selectedRequest.reason || ""}
                          onChange={(e) =>
                            setSelectedRequest((prevRequest) => ({
                              ...prevRequest,
                              reason: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="flex-none">
                      <div className="text-center">
                        <p className="text-xl font-bold">{days}</p>
                        <p>Days</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex mb-4">
                    <div className="mr-4">
                      <label className="font-semibold">Start Date:</label>
                      <DatePicker
                        selected={new Date(selectedRequest.startDate)}
                        onChange={(date) =>
                          setSelectedRequest((prevRequest) => ({
                            ...prevRequest,
                            startDate: date.toISOString(),
                          }))
                        }
                        className="block w-full border rounded p-2"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div>
                      <label className="font-semibold">End Date:</label>
                      <DatePicker
                        selected={new Date(selectedRequest.endDate)}
                        onChange={(date) =>
                          setSelectedRequest((prevRequest) => ({
                            ...prevRequest,
                            endDate: date.toISOString(),
                          }))
                        }
                        className="block w-full border rounded p-2"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleSubmit}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Submit Request
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-lg text-gray-600">
                Select a request to view details
              </p>
            )}
          </div>
        </div>

        {/* Message Display */}
        {isMessageVisible && (
          <div
            className={`absolute top-4 right-4 p-4 rounded-lg text-white ${
              messageType === "success" ? "bg-green-500" : "bg-red-500"
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewLeaverequests;
