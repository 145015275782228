import Navbar from "../components/Navbar.jsx";
import Sidebar from "../components/Sidebar.jsx";
import DashboardDetails from "../components/Dashboard/Dashboard_details.jsx";
import Tasks from "../components/Dashboard/Tasks.jsx";

const Dashboard = () => {
  return (
    <div className="flex h-screen">
      <Sidebar className="fixed h-full" />
      <div className="flex-1 bg-gray-100 divide-y overflow-auto">
        <Navbar pageTitle="Dashboard" />
        <div className="grid grid-cols-1 lg:grid-cols-4 divide-x-2 pt-4 bg-white">
          <div className="col-span-3">
            <DashboardDetails />
          </div>
          <div>
            <Tasks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
