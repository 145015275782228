// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { FaLeaf, FaShieldAlt, FaPercentage } from "react-icons/fa";
import BarChart from "../charts/BarChart";
import AttendanceSummary from "./Attendance_summary";
import TotalPayrollexpenses from "./Total_payrollexpenses";
import RecentPayroll from "./Recent_payroll";
import EmployeesSummary from "./Employees_summary";
// import { isUserLoggedIn } from "../ApiBaseUrl";

const Dashboard_details = () => {
  // const navigateToLogin = useNavigate();

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     const loggedIn = await isUserLoggedIn("checkLogin"); // Using the
  //     // console.log("login status", loggedIn.data.status_code);
  //     if (!loggedIn) {
  //       navigateToLogin("/"); // Redirect to login if not authenticated
  //     }
  //   };

  //   checkAuth();
  // }, [navigateToLogin]);

  const payRunDetails = {
    dateRange: "01/09/2024 to 30/09/2024",
    status: "APPROVED",
    netPay: "₹1,25,23,654.00",
    paymentDate: "30/09/2024",
    employeeCount: 226,
  };

  const deductionDetails = [
    {
      icon: (
        <FaLeaf className="bg-indigo-50 text-indigo-500 h-9 w-9 p-2.5 mb-4 rounded-full" />
      ),
      title: "EPF",
      amount: "₹11,97,638.00",
      color: "text-indigo-500",
    },
    {
      icon: (
        <FaShieldAlt className="bg-green-50 text-green-500 h-9 w-9 p-2.5 mb-4 rounded-full" />
      ),
      title: "ESI",
      amount: "₹65,505.00",
      color: "text-green-500",
    },
    {
      icon: (
        <FaPercentage className="bg-orange-50 text-orange-500 h-9 w-9 p-2.5 mb-4 rounded-full" />
      ),
      title: "TDS DEDUCTION",
      amount: "₹9,16,551.00",
      color: "text-orange-500",
    },
  ];

  return (
    <div className="bg-white px-6  pt-4">
      <div className="mb-8 grid grid-flow-col gap-6">
        <TotalPayrollexpenses />
        <EmployeesSummary />
        <AttendanceSummary />
      </div>

      <div className="">
        <div className="flex justify-start items-center mb-4">
          <div className="text-base lg:text-lg font-semibold">
            Process Pay Run
          </div>
          <div className="ml-2 text-base lg:text-lg">
            {payRunDetails.dateRange}
          </div>
          <span className="bg-green-600 text-white px-1 py-0 ml-2 text-xs rounded">
            {payRunDetails.status}
          </span>
        </div>

        <div className="rounded-lg border-green-500  border-l-2 px-6 pt-6 pb-3 shadow-xl ">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
            {[
              { label: "EMPLOYEES NET PAY", value: payRunDetails.netPay },
              { label: "PAYMENT DATE", value: payRunDetails.paymentDate },
              { label: "NO. OF EMPLOYEES", value: payRunDetails.employeeCount },
              {
                label: null,
                value: (
                  <button className="text-xs lg:text-sm bg-green-100 text-green-600 border border-green-400 px-2 py-1 rounded shadow-2xl">
                    View Details & Pay
                  </button>
                ),
              },
            ].map((item, index) => (
              <div key={index}>
                {item.label && (
                  <div className="text-xs text-gray-500 mb-1">{item.label}</div>
                )}
                <div className="text-base lg:text-xl font-semibold text-slate-700">
                  {item.value}
                </div>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 divide-x mt-8 mb-8">
            {deductionDetails.map((detail, index) => (
              <div
                key={index}
                className="flex flex-col items-center md:items-start pl-6"
              >
                {detail.icon}
                <h4 className={`text-xs ${detail.color}`}>{detail.title}</h4>
                <p className="text-xl font-semibold text-gray-800 mb-2">
                  {detail.amount}
                </p>
                <span className="text-xs text-blue-600 hover:underline">
                  View Details
                </span>
              </div>
            ))}
          </div>

          <div>
            <div className="text-xs font-bold text-gray-500">
              Pay your employees on 30/09/2024. Record it here once you have
              made the payment.
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-3 gap-6">
        <div className="col-span-2 bg-lime-50 rounded-lg shadow-xl p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="text-base lg:text-lg font-semibold ">
              Payroll Cost Summary
            </div>
            <select
              className="px-2 py-1 ml-2 text-xs rounded bg-green-100 text-green-600 border border-green-500"
              defaultValue="approved"
            >
              <option value="this">This Year</option>
              <option value="last">Last Year</option>
            </select>
          </div>
          <BarChart />
        </div>
        <RecentPayroll />
      </div>
    </div>
  );
};

export default Dashboard_details;
