import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

// Register the chart components
ChartJS.register(ArcElement, Tooltip);

const AttendanceSummary = () => {
    // Data for the pie chart
    const data = {
        labels: ['In Office', 'Half Day', 'Work from Home', 'On leave'],
        datasets: [
            {
                data: [63, 22, 6, 9],
                backgroundColor: ['#16a34a', '#22c55e', '#ffbf00', '#ff4343'],
                borderColor: ['#16a34a', '#22c55e', '#ffbf00', '#ff4343'],
                borderWidth: 1,
                cutout: '70%',
            },
        ],
    };

    const options = {
        plugins: {
            legend: { display: false },
        },
        maintainAspectRatio: false,
    };

    // Legend data
    const legendItems = [
        { label: 'Present', color: 'bg-chateauGreen', percentage: '63%', textColor: 'text-chateauGreen' },
        { label: 'Half Day', color: 'bg-amber-500', percentage: '6%', textColor: 'text-amber-500' },
        { label: 'Over Time', color: 'bg-emerald-500', percentage: '22%', textColor: 'text-emerald-500' },
        { label: 'Absent', color: 'bg-coralRed', percentage: '9%', textColor: 'text-coralRed' },
    ];

    return (
        <div className="max-w-lg p-4 bg-white rounded-xl shadow-lg">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h3 className="text-lg font-semibold">Attendance Summary</h3>
                    <p className="text-gray-500 text-sm">From 4-10 Sep, 2023</p>
                </div>
                <select
                    className="px-2 py-1 ml-2 text-xs rounded-xl border border-green-400 bg-green-100 text-green-600"
                    defaultValue="ThisWeek"
                >
                    <option value="ThisWeek">This Week</option>
                    <option value="LastWeek">Last Week</option>
                </select>
            </div>

            {/* Doughnut Chart Section */}
            <div className="flex flex-col lg:flex-row items-center justify-around">
                <div className="flex justify-center">
                    <div className="relative">
                        <div className="w-28 h-28">
                            <Doughnut data={data} options={options} />
                            <div className="absolute inset-0 flex justify-center items-center">
                                <span className="text-lg font-bold">63%</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Legend Section */}
                <div className="grid grid-cols-2 gap-4 text-center lg:text-left mt-6 lg:mt-0 ml-4">
                    {legendItems.map((item, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <div className={`${item.color} w-1 h-full rounded`}></div> {/* Vertical line */}
                            <div>
                                <p className="text-sm text-gray-500">{item.label}</p>
                                <p className={`text-xl font-semibold ${item.textColor}`}>{item.percentage}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AttendanceSummary;
