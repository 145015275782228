import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar";
import { FaCalendarAlt, FaClock, FaTimesCircle, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Sidebar from "../../components/Sidebar";

// Reusable StatItem component
const StatItem = ({ icon, actual, expected, actualDesc, expectedDesc, bgColor, iconColor }) => (
    <div className={`flex justify-around items-center p-4 ${bgColor} rounded-lg shadow-md`}>
        {/* First Column */}
        <div className="flex flex-col items-center">
            <div className="text-xl font-semibold">{actual}</div>
            <div className="text-sm text-gray-500">{actualDesc}</div>
        </div>

        {/* Divider with Centered Icon */}
        <div className="flex items-center">
            <div className="flex flex-col items-center">
                {React.cloneElement(icon, { size: 24, className: iconColor })}
                <div className="h-16 w-px bg-gray-400 mt-2"></div>
            </div>
        </div>

        {/* Second Column */}
        <div className="flex flex-col items-center">
            <div className="text-xl font-semibold">{expected}</div>
            <div className="text-sm text-gray-500">{expectedDesc}</div>
        </div>
    </div>
);

const AttendanceInputForm = ({ attendanceDays, handleAttendanceChange }) => {
    const scrollRef = useRef(null);

    const scroll = (direction) => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: direction === "left" ? -100 : 100,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            <div className="mb-2 text-black">
                <span className="mr-4 font-medium">Dates:</span>
            </div>
            <div className="flex items-center mb-4">
                {Object.keys(attendanceDays).length > 7 && (
                    <div className="cursor-pointer mr-1" onClick={() => scroll("left")}>
                        <FaChevronLeft size={20} className="text-gray-600" title="Scroll left" />
                    </div>
                )}
                <div className="flex overflow-x-auto space-x-2 scroll-container" ref={scrollRef}>
                    {Object.keys(attendanceDays).map((date, index) => (
                        <div
                            key={date}
                            className={`w-10 h-10 px-4 rounded-full flex items-center justify-center text-white ${attendanceDays[date] === "Present" ? "bg-green-500" : "bg-red-500"
                                }`}
                            title={`Day ${index + 1}: ${attendanceDays[date]}`}
                        >
                            <span>{index + 1}</span>
                        </div>
                    ))}
                </div>
                {Object.keys(attendanceDays).length > 7 && (
                    <div className="cursor-pointer ml-1" onClick={() => scroll("right")}>
                        <FaChevronRight size={20} className="text-gray-600" title="Scroll right" />
                    </div>
                )}
            </div>
        </>
    );
};

const UpdateAttendance = () => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [attendanceDays, setAttendanceDays] = useState({});
    const [attendanceDetails, setAttendanceDetails] = useState({
        date: "",
        checkIn: "",
        checkOut: "",
        workingHours: "",
        overtime: "",
        status: "Present",
    });

    // Fetch employees data from the JSON file
    useEffect(() => {
        fetch("/employeesData.json")
            .then((response) => response.json())
            .then((data) => {
                setEmployees(data.employeeAttendance);
            })
            .catch((error) => {
                console.error("Error fetching employees data:", error);
            });
    }, []);

    useEffect(() => {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const year = today.getFullYear();
        const daysInMonth = new Date(year, month, 0).getDate();
        const attendance = {};

        for (let day = 1; day <= daysInMonth; day++) {
            const date = `${year}-${month}-${String(day).padStart(2, "0")}`;
            attendance[date] = "Absent";
        }

        setAttendanceDays(attendance);
    }, []);

    const handleEmployeeSelect = (id) => {
        setSelectedEmployeeId(id);
        const employee = employees.find((emp) => emp.id === id);
        if (employee) {
            setAttendanceDays(employee.attendance);
        }
    };

    const handleSaveAttendance = () => {
        const updatedEmployees = employees.map((employee) =>
            employee.id === selectedEmployeeId ? { ...employee, attendance: attendanceDays } : employee
        );
        setEmployees(updatedEmployees);
        alert("attendance updated successfully!");
    };

    return (
        <div className="flex h-screen">
            <Sidebar className="fixed h-full" />
            <div className="flex-1 bg-white divide-y overflow-auto">
                <Navbar />
                <div className="container mx-auto p-6">
                    <h1 className="text-xl font-semibold text-blue-900 mb-6">Update Attendance</h1>
                    <div className="flex justify-between">
                        <select
                            className="p-2 border border-gray-300 rounded font-semibold"
                            value={selectedEmployeeId || ""}
                            onChange={(e) => handleEmployeeSelect(Number(e.target.value))}
                        >
                            <option value="" disabled className="font-semibold">
                                Select Employee
                            </option>
                            {employees.map((employee) => (
                                <option key={employee.id} value={employee.id} className="font-semibold">
                                    {employee.name} ({employee.emp_id})
                                </option>
                            ))}
                        </select>
                        <select
                            className="p-2 ml-4 border border-gray-300 rounded"
                            onChange={(e) => handleEmployeeSelect(Number(e.target.value))} // Adjust as needed
                        >
                            <option value="October" disabled>Select Month</option>
                            <option>October</option>
                        </select>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 py-6">
                        <StatItem
                            icon={<FaCalendarAlt />}
                            actual="20"
                            expected="24"
                            actualDesc="Actual Working Days"
                            expectedDesc="Expected Working Days"
                            bgColor="bg-zink-50"
                            iconColor="text-purple-600"
                        />
                        <StatItem
                            icon={<FaClock />}
                            actual="200:00"
                            expected="240:00"
                            actualDesc="Actual Working Hours"
                            expectedDesc="Expected Working Hours"
                            bgColor="bg-sky-50"
                            iconColor="text-blue-600"
                        />
                        <StatItem
                            icon={<FaTimesCircle />}
                            actual="2"
                            expected="3"
                            actualDesc="Actual Leaves"
                            expectedDesc="Planned Leaves"
                            bgColor="bg-slate-50"
                            iconColor="text-red-600"
                        />
                    </div>

                    {selectedEmployeeId && (
                        <div>
                            <AttendanceInputForm
                                attendanceDays={attendanceDays}
                                handleAttendanceChange={(date, status) =>
                                    setAttendanceDays((prev) => ({ ...prev, [date]: status }))
                                }
                            />

                            <div className="border-b border-gray-200 py-3">
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                    {Object.keys(attendanceDetails).map((key) => (
                                        <div key={key}>
                                            <label className="block text-sm font-medium text-gray-700">{key}</label>
                                            {key === "status" ? (
                                                <select
                                                    className="border border-gray-300 rounded p-2 w-full"
                                                    value={attendanceDetails[key]}
                                                    onChange={(e) =>
                                                        setAttendanceDetails((prev) => ({
                                                            ...prev,
                                                            [key]: e.target.value,
                                                        }))
                                                    }
                                                >
                                                    <option value="Present">Present</option>
                                                    <option value="Absent">Absent</option>
                                                    <option value="Leave">Leave</option>
                                                </select>
                                            ) : (
                                                <input
                                                    type={key === "date" ? "text" : "time"}
                                                    className="border border-gray-300 rounded p-2 w-full"
                                                    value={attendanceDetails[key]}
                                                    onChange={(e) =>
                                                        setAttendanceDetails((prev) => ({
                                                            ...prev,
                                                            [key]: e.target.value,
                                                        }))
                                                    }
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <button
                                className="mt-4 p-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                onClick={handleSaveAttendance}
                            >
                                Save Attendance
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpdateAttendance;
