// src/components/charts/BarChart.js

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register necessary components
Chart.register(...registerables);

const BarChart = () => {
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Total Net Pay (Lakhs)",
        data: [10, 20, 40, 30, 50, 60, 80, 50, 60, 70, 80, 90],
        backgroundColor: "#16a34a", // Teal for bars
        borderColor: "#98ff90",
        borderWidth: 0,
        borderRadius: {
          topLeft: 20,
          topRight: 20,
          bottomLeft: 20,
          bottomRight: 20,
        },
        hoverBackgroundColor: "#f59e0b", // Stronger blue on hover
      },
    ],
  };

  // Breakdown data for tooltip
  const breakdownData = [
    { salary: 5, overtime: 2, bonus: 3 }, // Jan
    { salary: 10, overtime: 5, bonus: 5 }, // Feb
    { salary: 20, overtime: 10, bonus: 10 }, // Mar
    { salary: 15, overtime: 10, bonus: 5 }, // Apr
    { salary: 25, overtime: 15, bonus: 10 }, // May
    { salary: 30, overtime: 20, bonus: 10 }, // Jun
    { salary: 40, overtime: 25, bonus: 15 }, // Jul
    { salary: 30, overtime: 15, bonus: 5 }, // Aug
    { salary: 35, overtime: 15, bonus: 10 }, // Sep
    { salary: 40, overtime: 25, bonus: 5 }, // Oct
    { salary: 45, overtime: 30, bonus: 5 }, // Nov
    { salary: 50, overtime: 25, bonus: 15 }, // Dec
  ];
    
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          title: function () {
            return 'Expenses Breakdown'; // Fixed title
          },
          label: function (context) {
            const monthIndex = context.dataIndex;
            const { salary, overtime, bonus } = breakdownData[monthIndex];
      
            return [
              `Total: ₹${context.raw} Lakhs`, // No color indicator for this
              `Salary: ₹${salary} Lakhs`,
              `Overtime: ₹${overtime} Lakhs`,
              `Bonus: ₹${bonus} Lakhs`,
            ];
          },
          labelTextColor: function () {
            return '#000'; // Set text color for the labels
          }
        },
        displayColors: false, // Disable the color boxes next to the labels
        backgroundColor: "#f0fdf4", // Translucent background
        titleColor: "#000", // Dark text for title
        bodyColor: "#000", // Dark text for body
        borderColor: "#98ff90", // Teal border
        borderWidth: 1,
        borderRadius: 10,
        padding: 10,
        titleFont: { size: 14, weight: 'bold' },
        bodyFont: { size: 12 },
      },
      


    },
    scales: {
      x: {
        grid: {
          display: false, // Hide X-axis grid lines
        },
      },
      y: {
        display: false, // Remove Y-axis scale
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
